import { Button, Dropdown, DropdownItem, IconButton } from '@mediahuis/chameleon-react';
import { ChevronDown } from '@mediahuis/chameleon-theme-wl/icons';
import { useRef } from 'react';

import { logoutLabel } from '@hubcms/brand';
import type { Identity } from '@hubcms/domain-auth';
import type { NavItem } from '@hubcms/domain-navigation';

import { NavigationItem } from '../NavigationItem';

import { NavigationUserMenuAvatar } from './NavigationUserMenuAvatar';
import styles from './navigation-user-menu.module.scss';

type NavigationUserMenuAuthenticatedProps = {
  items: NavItem[];
  size?: 'sm' | 'lg';
  user: Identity;
  onLogout: () => void;
};

export function NavigationUserMenuAuthenticated({ items, size = 'lg', user, onLogout }: NavigationUserMenuAuthenticatedProps) {
  const anchorRef = useRef<typeof Button>(null);
  const dropdownRef = useRef<typeof Dropdown>(null);

  const avatarLabel = `${user.givenName.substring(0, 1)}${user.familyName.substring(0, 1)}`.toUpperCase();
  const buttonLabel = `${user.givenName} ${user.familyName}`;

  return (
    <>
      {size === 'sm' ? (
        <IconButton
          aria-label={buttonLabel}
          className={styles.navigationUserMenuButton}
          data-testid="navigation-usermenu"
          icon={<NavigationUserMenuAvatar>{avatarLabel}</NavigationUserMenuAvatar>}
          ref={anchorRef}
          size="lg"
          onClick={() => dropdownRef.current.toggle()}
        />
      ) : (
        <Button
          className={styles.navigationUserMenuButton}
          data-testid="navigation-usermenu"
          iconLeft={<NavigationUserMenuAvatar>{avatarLabel}</NavigationUserMenuAvatar>}
          iconRight={ChevronDown}
          ref={anchorRef}
          size="sm"
          onClick={() => dropdownRef.current.toggle()}
        >
          {buttonLabel}
        </Button>
      )}

      <Dropdown anchorRef={anchorRef} placement="bottom-end" ref={dropdownRef} className={styles.dropdown}>
        {items.map(item => (
          <NavigationItem key={item.uniqueId} navItem={item}>
            {({ href }) => <DropdownItem as="a" href={href} target={item.target} title={item.text} />}
          </NavigationItem>
        ))}
        <DropdownItem data-testid="navigation-usermenu-logout" title={logoutLabel} onClick={onLogout} />
      </Dropdown>
    </>
  );
}
